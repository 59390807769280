import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  BannerSmall,
  TextBox,
  CallUs,
  BannerLinks,
} from '../components'

import contacts from '../components/CallUs/DefaultContacts'

import BannerImage from '../assets/balance.jpg'
import beratung from '../assets/bannerlinks/verlaessliche.beratung.jpg'
import laptop from '../assets/bannerlinks/bannerbox.eglo.pim.jpg'

const boxes = [
  {
    text: "Let's do it!",
    image: beratung,
    link: '/kontakt',
    linkText: 'Kontakt'
  },
  {
    text: 'Join the team!',
    image: laptop,
    link: '/karriere',
    linkText: 'Mehr lesen'
  }
]

const Balance = () => (
  <Layout>
    <Helmet
      title="Teil vom Team der holzweg GmbH® werden"
      meta={[
        {
          name: 'description',
          content:
            'Das Team der holzweg GmbH® schätzt ein familiäres Arbeitsumfeld und Platz für Innovation und Spaß. Möchten auch Sie Ihre Karriere bei uns starten?'
        },
        {
          name: 'keywords',
          content: ''
        },
        {
          property: 'og:description',
          content:
            'Das Team der holzweg GmbH® schätzt ein familiäres Arbeitsumfeld und Platz für Innovation und Spaß. Möchten auch Sie Ihre Karriere bei uns starten?'
        },
        {
          property: 'og:image',
          content: 'https://www.holzweg.com/static/balance-dac2dcf9574621700be220484269619e.jpg'
        }
      ]}>
    </Helmet>
    <BannerSmall image={BannerImage} title="Internetagentur Innsbruck" />
    <TextBox
      title="Über uns"
      text={
        <span>
          <p>
            Als Internetagentur in Innsbruck bieten wir bei der holzweg GmbH seit 1995 individuelle Software- und Internetlösungen für Unternehmen jeder Größe und konnten schon zahlreiche Kund:innen und Projekten betreuen. Unser Team aus Entwickler:innen setzt dabei auf modernste Technologien und Trends, um maßgeschneiderte Lösungen im Bereich der Webentwicklung umzusetzen.
          </p>
          <p>
            Darüber hinaus sind wir spezialisiert auf die Implementierung von Custom ERP-Systemen und kundenspezifische Softwarelösungen. Bei diesen bieten wir unseren Kund:innen eine individuelle Lösung zur Automatisierung und Optimierung ihrer Geschäftsprozesse. Mit unseren Custom ERP-Systemen können Unternehmen ihre Arbeitsabläufe und Arbeitszeit besser planen.
          </p>
          <p>
            Gemeinsam mit Ihnen arbeiten wir daran, Ihre spezifischen Anforderungen zu erfüllen und Ihre Online-Präsenz zu maximieren. Als führende Internetagentur in Innsbruck und Umgebung sorgen wir dafür, dass wir gemeinsam Ihre digitalen Projekte erfolgreich umsetzen.
          </p>
        </span>
      }
    />
    <CallUs contacts={contacts} title="Gerne für Sie da" />
    <TextBox
      title="Walk with us on the holzweg"
      text="Die holzweg GmbH steht für Kreativität, Innovation und Erfolg. Bei uns ist der Weg das Ziel, und manchmal kann es sogar positiv sein, am holzweg zu sein. Wir stehen für individuelle Lösungen, die genau auf Ihre Bedürfnisse zugeschnitten sind - sei es im Bereich Websites, Custom ERP Systeme oder individuelle Softwarelösungen. Entdecken Sie mit uns den Weg zum Erfolg und lassen Sie sich von unserer Kreativität begeistern!"
    />
    <BannerLinks boxes={boxes} />
  </Layout>
)

export default Balance
